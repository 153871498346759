import React from 'react'
import classnames from 'classnames'

const DEFAULT = 'font-headers';
const HEAD_TITLE = 'text-3xl md:text-5xl lg:text-6xl font-bold whitespace-no-wrap leading-tight';
const HEAD_TITLE_WRAP = 'text-3xl md:text-5xl lg:text-6xl font-bold leading-tight';
const SUB_TITLE = 'text-3xl md:text-4xl font-semibold';
const ACTION_TITLE = 'text-3xl font-bold text-dark-blue-600';
const NAV_TITLE = 'text-xl font-medium text-dark-blue-600';
const CAPTION = 'text-2xl font-semibold';

export default function Heading(props) {
    const {
        children,
        type,
        className,
        tagName
    } = props;

    const conditionalClasses = {
        [HEAD_TITLE]: type === 'head-title',
        [HEAD_TITLE_WRAP]: type === 'head-title-wrap',
        [SUB_TITLE]: type === 'sub-title',
        [ACTION_TITLE]: type === 'action-title',
        [NAV_TITLE]: type === 'nav-title',
        [CAPTION]: type === 'caption',
    };

    if (tagName === 'h1') {
        return <h1 className={classnames(DEFAULT, className, conditionalClasses)}>{children}</h1>
    } else if (tagName === 'h2') {
        return <h2 className={classnames(DEFAULT, className, conditionalClasses)}>{children}</h2>
    } else if (tagName === 'h3') {
        return <h3 className={classnames(DEFAULT, className, conditionalClasses)}>{children}</h3>
    } else if (tagName === 'h4') {
        return <h4 className={classnames(DEFAULT, className, conditionalClasses)}>{children}</h4>
    } else if (tagName === 'h5') {
        return <h5 className={classnames(DEFAULT, className, conditionalClasses)}>{children}</h5>
    } else if (tagName === 'h6') {
        return <h6 className={classnames(DEFAULT, className, conditionalClasses)}>{children}</h6>
    } else {
        return <p className={classnames(DEFAULT, className, conditionalClasses)}>{children}</p>
    }
}
