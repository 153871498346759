import React, { useContext, useEffect } from 'react'
import classNames from 'classnames'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { Context as ThemeContext } from 'src/context/ThemeContext'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'
import MarketingLayout from 'src/components/layout/MarketingLayout'
import Heading from 'src/components/typography/Heading'


const UNDERLINED = 'text-white text-lg mx-4 inline-block md:text-xl link-underline secondary cursor-pointer'

export default function NotFound(props) {
    const { t } = useTranslation()
    const { setTheme } = useContext(ThemeContext)
    const { home } = useLocalizedRoutes()

    useEffect(() => setTheme('normal'), [])

    return (
        <MarketingLayout {...props} title={t('general.nav.notFound')}>
            <div className="px-4 bg-baby-blue-600 w-full py-12 md:pt-16 md:pb-40">
                <div className="mx-auto md:w-2/3 text-center">
                    <Heading type='head-title-wrap'>
                        <span className="text-white">{t('404.titleOne')} </span>
                        <span className="text-egg-shell-600">{t('404.titleTwo')}:</span>
                        <span className="text-white"> {t('404.titleThree')} </span>
                    </Heading>
                    <div className="mt-4">
                        <span
                            className={classNames(UNDERLINED)}
                            onClick={() => navigate(home)}>
                            {t('404.backToHome')}
                        </span>
                    </div>
                </div>
            </div>
            <div className="container mx-auto lg:-mt-16">
                <img alt="404" className="w-full lg:w-4/12 mx-auto" src="https://media.giphy.com/media/26BGwSs39WPuxsFhe/source.gif"/>
            </div>
        </MarketingLayout>
    )
}

